import React, { Fragment } from "react";
import { Hero, ProductItem } from "src/components";
import { PrismicPageBodyMenuSection, PrismicDownloads } from "src/graphqlTypes";
import { Container, Typography, Box, Button } from "@material-ui/core";
import { useStaticQuery, graphql } from "gatsby";
import styles from "./Styles.module.scss";

type MenuSectionTemplateProps = {
  data: PrismicPageBodyMenuSection;
};

const MenuSectionTemplate = ({ data }: MenuSectionTemplateProps) => {
  const {
    prismicDownloads
  }: {
    prismicDownloads: PrismicDownloads;
  } = useStaticQuery(query);

  return (
    <Hero id="menu" size="large">
      <Hero.Body>
        <Container>
          <Typography variant="h2" gutterBottom>
            <strong>{data.primary.menu_title.text}</strong>
          </Typography>

          {data.items.map(({ section }, idx) => (
            <Fragment key={idx}>
              <Typography variant="h3" color="secondary" gutterBottom>
                <strong>{section.document.data.title.text}</strong>
              </Typography>

              <div className={styles.product__grid}>
                {section.document.data["items"]?.map(({ item }, idx) => {
                  const menuItem = item.document.data;

                  return (
                    <ProductItem
                      title={menuItem?.title.text}
                      description={menuItem?.description.text}
                      price={menuItem?.price}
                      image={menuItem?.image.fluid}
                      imageAlt={menuItem?.image?.alt}
                      badgeType={menuItem?.badge_type}
                      key={idx}
                    />
                  );
                })}
              </div>
            </Fragment>
          ))}

          <Box display="flex" justifyContent="center">
            <Button
              href={prismicDownloads.data.menu_url.url}
              rel="noopener noreferrer"
              target="_blank"
              variant="outlined"
              color="secondary"
              size="large"
            >
              Download Our Menu
            </Button>
          </Box>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const query = graphql`
  query {
    prismicDownloads {
      data {
        menu_url {
          url
        }
      }
    }
  }
`;

export default MenuSectionTemplate;
