import React, { Fragment, useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Checkbox,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormLabel,
  FormHelperText
} from "@material-ui/core";
import { PrismicPageBodyReportAnIssueForm } from "src/graphqlTypes";
import styles from "./Styles.module.scss";
import { useForm, Controller } from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
import { encodeFormData } from "src/utils/encodeFormData";

type IssueFormProps = {
  data: PrismicPageBodyReportAnIssueForm;
};

const IssueFormTemplate = ({ data }: IssueFormProps) => {
  const {
    handleSubmit,
    register,
    errors,
    reset,
    setValue,
    control
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [checkedValues, setCheckedValues] = useState("");
  const [selectValues, setSelectValues] = useState("");
  const [validateMissedFields, setValidateMissedFields] = useState(false);

  function handleCheck(checkedName) {
    const newNames = checkedValues?.includes(checkedName)
      ? checkedValues?.filter(name => name !== checkedName)
      : [...(checkedValues ?? []), checkedName];
    setCheckedValues(newNames);
    return newNames;
  }

  function handleSelect(checkedName) {
    setSelectValues(checkedName);
  }

  const onSubmit = async data => {
    setValidateMissedFields(true);

    if (!checkedValues.length || selectValues === "") {
      console.log("form not valid");
    } else {
      try {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encodeFormData({ "form-name": "report-issue", ...data })
        });

        if (response.ok) {
          setSuccess(true);
          const body = data;
          const awsResponse = await window.fetch(
            "/.netlify/functions/issue-aws",
            {
              method: "POST",
              body: JSON.stringify(body)
            }
          );
          reset();
        } else {
          console.error("Request failed", response);
          setError(
            "There was an error submitting your message, please try again."
          );
        }
      } catch (e) {
        console.error(e);
        setError("Something went wrong, please try again.");
      }
    }
  };

  const theme = useTheme();

  const InputItems = data?.items;
  const PrimaryData = data?.primary;

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        name="report-issue"
        netlify-honeypot="bot-field"
        data-netlify="true"
        className={styles.section_form}
      >
        {InputItems?.length &&
          InputItems?.map(inputItem => {
            const formName = inputItem?.field_name?.text.toLowerCase();
            function camelize(str) {
              return str
                .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                  return index === 0 ? word.toLowerCase() : word.toUpperCase();
                })
                .replace(/\s+/g, "");
            }

            const formNameCamelize = camelize(formName);
            return inputItem?.field_type === "Text Field" ? (
              <TextField
                variant="outlined"
                label={inputItem?.field_name?.text}
                className={styles.section__input}
                name={formNameCamelize}
                inputRef={register({ required: true })}
                error={!!errors[formNameCamelize]}
                helperText={
                  errors[formNameCamelize]?.type === "required" &&
                  `${inputItem?.field_name?.text} is required`
                }
                fullWidth
              />
            ) : inputItem?.field_type === "Date Field" ? (
              <TextField
                id="date"
                label={inputItem?.field_name?.text}
                type="date"
                variant="outlined"
                name={formNameCamelize}
                defaultValue={today}
                error={!!errors[formNameCamelize]}
                helperText={
                  errors[formNameCamelize]?.type === "required" &&
                  `${inputItem?.field_name?.text} is required`
                }
                inputRef={register({ required: true })}
                className={styles.section_input_date}
                InputLabelProps={{
                  shrink: true
                }}
              />
            ) : inputItem?.field_type === "Option Select" ? (
              <FormControl className={styles.section__form_control}>
                <InputLabel className={styles.select_label}>
                  {inputItem?.field_name?.text}
                </InputLabel>
                <Controller
                  name={formNameCamelize}
                  control={control}
                  as={
                    <Select
                      id={formNameCamelize}
                      className={styles.section_input_select}
                      fullWidth
                    >
                      <MenuItem
                        onClick={() =>
                          handleSelect(inputItem?.option_select_one?.text)
                        }
                        value={inputItem?.option_select_one?.text}
                      >
                        {inputItem?.option_select_one?.text}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleSelect(inputItem?.option_select_one?.text)
                        }
                        value={inputItem?.option_select_two?.text}
                      >
                        {inputItem?.option_select_two?.text}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleSelect(inputItem?.option_select_one?.text)
                        }
                        value={inputItem?.option_select_three?.text}
                      >
                        {inputItem?.option_select_three?.text}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleSelect(inputItem?.option_select_one?.text)
                        }
                        value={inputItem?.option_select_four?.text}
                      >
                        {inputItem?.option_select_four?.text}
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handleSelect(inputItem?.option_select_one?.text)
                        }
                        value={inputItem?.option_select_five?.text}
                      >
                        {inputItem?.option_select_five?.text}
                      </MenuItem>
                    </Select>
                  }
                />
                {validateMissedFields && selectValues === "" ? (
                  <p className={styles.field_error}>This fields is required</p>
                ) : (
                  <></>
                )}
              </FormControl>
            ) : inputItem?.field_type === "Long Response" ? (
              <FormControl className={styles.form_control}>
                <Controller
                  name={formNameCamelize}
                  control={control}
                  as={
                    <TextareaAutosize
                      rows={2}
                      aria-label="minimum height"
                      className={styles.text_area}
                      placeholder={inputItem?.field_name?.text}
                    />
                  }
                />
              </FormControl>
            ) : inputItem?.field_type === "Multiple Select" ? (
              <FormControl className={styles.form_control}>
                <InputLabel className={styles.check_box_label}>
                  {inputItem?.field_name?.text}
                </InputLabel>
                <FormControlLabel
                  control={
                    <Controller
                      name={inputItem?.field_name?.text}
                      control={control}
                      onChange={() =>
                        handleCheck(inputItem?.option_select_one?.text)
                      }
                      as={
                        <Checkbox
                          aria-label={inputItem?.option_select_one?.text}
                          value={inputItem?.option_select_one?.text}
                        />
                      }
                    />
                  }
                  label={inputItem?.option_select_one?.text}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name={inputItem?.field_name?.text}
                      control={control}
                      onChange={() =>
                        handleCheck(inputItem?.option_select_two?.text)
                      }
                      as={
                        <Checkbox
                          aria-label={inputItem?.option_select_two?.text}
                          value={inputItem?.option_select_two?.text}
                        />
                      }
                    />
                  }
                  label={inputItem?.option_select_two?.text}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name={inputItem?.field_name?.text}
                      control={control}
                      onChange={() =>
                        handleCheck(inputItem?.option_select_three?.text)
                      }
                      as={
                        <Checkbox
                          aria-label={inputItem?.option_select_three?.text}
                          value={inputItem?.option_select_three?.text}
                        />
                      }
                    />
                  }
                  label={inputItem?.option_select_three?.text}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name={inputItem?.field_name?.text}
                      control={control}
                      onChange={() =>
                        handleCheck(inputItem?.option_select_four?.text)
                      }
                      as={
                        <Checkbox
                          aria-label={inputItem?.option_select_four?.text}
                          value={inputItem?.option_select_four?.text}
                        />
                      }
                    />
                  }
                  label={inputItem?.option_select_four?.text}
                />
                <FormControlLabel
                  control={
                    <Controller
                      name={inputItem?.field_name?.text}
                      control={control}
                      onChange={() =>
                        handleCheck(inputItem?.option_select_five?.text)
                      }
                      as={
                        <Checkbox
                          aria-label={inputItem?.option_select_five?.text}
                          value={inputItem?.option_select_five?.text}
                        />
                      }
                    />
                  }
                  label={inputItem?.option_select_five?.text}
                />
                {validateMissedFields && !checkedValues.length ? (
                  <p className={styles.field_error}>This fields is required</p>
                ) : (
                  <></>
                )}
              </FormControl>
            ) : (
              <FormControl className={styles.radio_group}>
                <FormLabel id={inputItem?.field_name?.text}>
                  {inputItem?.field_name?.text}
                </FormLabel>
                <Controller
                  rules={{ required: true }}
                  control={control}
                  name={inputItem?.field_name?.text}
                  defaultValue="yes"
                  as={
                    <RadioGroup>
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="no"
                        control={<Radio />}
                        label="No"
                      />
                    </RadioGroup>
                  }
                />
              </FormControl>
            );
          })}
        <Box display="flex" my={2} clone>
          <ReCaptcha
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            onChange={c => setValue("g-recaptcha-response", c)}
          />
        </Box>
        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          Submit
        </Button>
        <TextField
          variant="outlined"
          className={styles.section__hidden_input}
          name="emailText"
          inputRef={register({ required: true })}
          value={PrimaryData?.auto_response_body.text}
          fullWidth
        />
      </form>
      {success && (
        <Box bgcolor={theme.palette.success.main} color="white" p={2}>
          <Typography>
            {PrimaryData?.form_submission_success_response?.text}
          </Typography>
        </Box>
      )}

      {error && (
        <Box bgcolor={theme.palette.error.main} color="white" p={2}>
          <Typography>{error}</Typography>
        </Box>
      )}
    </Fragment>
  );
};

export default IssueFormTemplate;
