import React from "react";
import { Hero } from "src/components";
import { Container, Box, useTheme, Typography } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import { PrismicPageBodyStoryCarouselHorizontal } from "src/graphqlTypes";

type CarouselStorySectionTemplateProps = {
  data: PrismicPageBodyStoryCarouselHorizontal;
};

export const CarouselStorySectionTemplate = ({
  data
}: CarouselStorySectionTemplateProps) => {
  const theme = useTheme();
  return (
    <Hero size="large">
      <Hero.Body>
        <Container>
          <Typography variant="h1" component="h2" gutterBottom>
            <strong>{data.primary.story_carousel_title.text}</strong>
          </Typography>

          {data.primary?.story_carousel_subtitle?.text && (
            <Typography
              variant="h4"
              component="p"
              color="error"
              style={{ marginBottom: theme.spacing(3) }}
            >
              <strong>{data.primary?.story_carousel_subtitle?.text}</strong>
            </Typography>
          )}

          <Box
            display="grid"
            gridTemplateColumns={[
              "1fr",
              data?.primary?.story_carousel_layout.toLocaleLowerCase() ===
              "horizontal"
                ? "repeat(3, 1fr)"
                : "1fr"
            ]}
            gridGap={theme.spacing(12)}
            style={{ marginTop: theme.spacing(6) }}
          >
            {" "}
            {data?.primary?.story_carousel_layout.toLocaleLowerCase() ===
            "horizontal"
              ? data?.items?.map((story, idx) => (
                  <Box
                    display="grid"
                    gridTemplateColumns="1fr"
                    gridTemplateRows="350px max-content max-content"
                    key={idx}
                  >
                    <Box mb={3} clone>
                      <GatsbyImage
                        fluid={story.carousel_story_image?.fluid}
                        alt={story.carousel_story_image?.alt}
                      />
                    </Box>

                    <Typography
                      variant="h4"
                      component="p"
                      color="error"
                      style={{ marginBottom: theme.spacing(3) }}
                    >
                      <strong>{story.carousel_story_title.text}</strong>
                    </Typography>

                    {story.carousel_story_content.raw.map((content, idx) => (
                      <Typography
                        color="textPrimary"
                        key={`story-${story.carousel_story_title.text}-${idx}`}
                        paragraph
                      >
                        {content.text}
                      </Typography>
                    ))}
                  </Box>
                ))
              : data?.items?.map((story, idx) => (
                  <Box
                    display="grid"
                    gridTemplateColumns={["1fr", "1fr 2fr"]}
                    gridTemplateRows="1fr"
                    gridGap={[theme.spacing(4), theme.spacing(8)]}
                    key={idx}
                  >
                    <Box mb={3} clone>
                      <GatsbyImage
                        fluid={story.carousel_story_image?.fluid}
                        alt={story.carousel_story_image?.alt}
                      />
                    </Box>

                    <Box>
                      <Typography
                        variant="h3"
                        component="p"
                        color="textPrimary"
                        style={{ marginBottom: theme.spacing(3) }}
                      >
                        <strong>{story.carousel_story_title.text}</strong>
                      </Typography>

                      {story.carousel_story_content.raw.map((content, idx) => (
                        <Typography
                          color="textPrimary"
                          key={`story-${story.carousel_story_title.text}-${idx}`}
                          paragraph
                        >
                          {content.text}
                        </Typography>
                      ))}
                    </Box>
                  </Box>
                ))}
          </Box>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default CarouselStorySectionTemplate;
