import React from "react";
import { PrismicPageBodyPressSection } from "src/graphqlTypes";
import { Hero } from "src/components";
import { Container, Typography, Grid, Link } from "@material-ui/core";
import GatsbyImage, { FluidObject } from "gatsby-image";
import styles from "./Styles.module.scss";

type PressSectionTemplateProps = {
  data: PrismicPageBodyPressSection;
};

const PressSectionTemplate = ({ data }: PressSectionTemplateProps) => {
  return (
    <Hero size="large">
      <Hero.Body>
        <Container>
          <Typography variant="h2" gutterBottom>
            <strong>{data.primary.section_title.text}</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            {data.primary.section_subtitle.text}
          </Typography>

          <Grid container spacing={4}>
            {data.items
              .sort((a, b) => (a.article_date < b.article_date ? 1 : -1))
              .map(
                (
                  {
                    article_title,
                    article_description,
                    article_image,
                    article_date,
                    url
                  },
                  idx
                ) => (
                  <Grid item xs={12} md={4} key={idx}>
                    <PressItem
                      title={article_title.text}
                      description={article_description.text}
                      image={article_image.fluid as FluidObject}
                      imageAlt={article_image.alt}
                      date={article_date}
                      link={url.url}
                    />
                  </Grid>
                )
              )}
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const PressItem = ({
  title,
  description,
  image,
  imageAlt,
  date,
  link
}: {
  title: string;
  description: string;
  image: FluidObject;
  imageAlt: string;
  date: string;
  link: string;
}) => {
  const getFormattedDate = (date: string) => {
    const d = new Date(date);

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec"
    ];
    const formattedDate = `${
      months[d.getMonth()]
    } ${d.getDate()}, ${d.getFullYear()}`;

    return formattedDate;
  };

  return (
    <div className={styles.press_item}>
      <div className={styles.press_item_image__container}>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={title}
        >
          <GatsbyImage
            fluid={image}
            alt={imageAlt}
            className={styles.press_item_image}
          />
        </a>

        <div className={styles.press_item_image__date}>
          {getFormattedDate(date)}
        </div>
      </div>

      <Link
        color="textPrimary"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        aria-label={title}
      >
        <Typography variant="h6" component="p" gutterBottom>
          <strong>{title}</strong>
        </Typography>
      </Link>

      <Typography color="textSecondary" paragraph>
        {description}
      </Typography>
    </div>
  );
};

export default PressSectionTemplate;
