import React from "react";
import { Hero, Nav } from "src/components";
import { Typography, Container } from "@material-ui/core";
import { PrismicPageBodyHero } from "src/graphqlTypes";

type HeroTemplateProps = {
  data: PrismicPageBodyHero;
};

const HeroTemplate = ({ data }: HeroTemplateProps) => {
  return (
    <Hero size="large" color="#000">
      <Hero.Header>
        <Nav />
      </Hero.Header>

      <Hero.Body>
        <Container>
          {data.primary?.hero_caption && (
            <Typography>{data.primary.hero_caption.text}</Typography>
          )}

          {data.primary?.hero_title && (
            <Typography variant="h1" align="center">
              <strong>{data.primary.hero_title.text}</strong>
            </Typography>
          )}
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default HeroTemplate;
