import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Hero } from "src/components";
import { PrismicPageBodyQuicklinksSection } from "src/graphqlTypes";
import { Container, Grid, Typography } from "@material-ui/core";
import styles from "./Styles.module.scss";

type QuickLinksTemplateProps = {
  data: PrismicPageBodyQuicklinksSection;
};

const QuickLinksTemplate = ({ data }: QuickLinksTemplateProps) => {
  return (
    <Hero color="#ffffff">
      <Hero.Body>
        <Container>
          <Typography variant="h4" className={styles.section_header}>
            {data?.primary?.section_title?.text}
          </Typography>
          <Grid container spacing={2}>
            {data.items.length &&
              data.items.map(item => {
                return (
                  <Grid item xs={12} md={6} spacing={2}>
                    <div className={styles.link__item}>
                      {item.link_type === "pdf" && (
                        <a
                          href={item?.pdf_file?.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item?.title_link?.text}
                        </a>
                      )}
                      {item.link_type === "internal" && (
                        <AnchorLink to={item?.internal_link?.text}>
                          {item?.title_link?.text}
                        </AnchorLink>
                      )}
                      {item.link_type === "phone number" && (
                        <a href={item?.internal_link?.text}>
                          {item?.title_link?.text}
                        </a>
                      )}
                      <svg
                        id="SVGDoc"
                        width="21"
                        height="20"
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        viewBox="0 0 21 20"
                      >
                        <defs></defs>
                        <desc>Generated with Avocode.</desc>
                        <g>
                          <g>
                            <title>external-link</title>
                            <g>
                              <title>Path 14397</title>
                              <path
                                d="M16.86719,11v0v6c0,1.10457 -0.89543,2 -2,2h-11c-1.10457,0 -2,-0.89543 -2,-2v-11c0,-1.10457 0.89543,-2 2,-2h6v0"
                                fillOpacity="0"
                                fill="#ffffff"
                                strokeDashoffset="0"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeOpacity="1"
                                stroke="#ed1c24"
                                strokeMiterlimit="20"
                                strokeWidth="2"
                              ></path>
                            </g>
                            <g>
                              <path
                                d="M13.86719,1v0h6v0v6v0"
                                fillOpacity="0"
                                fill="#ffffff"
                                strokeDashoffset="0"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeOpacity="1"
                                stroke="#ed1c24"
                                strokeMiterlimit="20"
                                strokeWidth="2"
                              ></path>
                            </g>
                            <g>
                              <path
                                d="M9,12l11,-11"
                                fillOpacity="0"
                                fill="#ffffff"
                                strokeDashoffset="0"
                                strokeLinejoin="round"
                                strokeLinecap="round"
                                strokeOpacity="1"
                                stroke="#ed1c24"
                                strokeMiterlimit="20"
                                strokeWidth="2"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </div>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default QuickLinksTemplate;
