import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Hero } from "src/components";
import { PrismicPageBodyCateringInformation } from "src/graphqlTypes";
import { Container, Box, Grid, Typography, Button } from "@material-ui/core";
import styles from "./Styles.module.scss";
import GatsbyImage from "gatsby-image";
import { Link as GatsbyLink } from "gatsby";

type CateringInformationProps = {
  data: PrismicPageBodyCateringInformation;
};

const CateringInformation = ({ data }: CateringInformationProps) => {
  console.log(data);
  /*
  {item.link_type === "pdf" && (
    <a
      href={item?.pdf_file?.url}
      target="_blank"
      rel="noopener noreferrer"
    >
      {item?.title_link?.text}
    </a>
  )}
  {item.link_type === "internal" && (
    <AnchorLink to={item?.internal_link?.text}>
      {item?.title_link?.text}
    </AnchorLink>
  )}
  {item.link_type === "phone number" && (
    <a href={item?.internal_link?.text}>
      {item?.title_link?.text}
    </a>
  )}
  */
  return (
    <Hero color="#ffffff">
      <Hero.Body>
        <Container>
          <Grid container spacing={8}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" className={styles.section_title}>
                {data?.primary?.title1?.text}
              </Typography>
              <Typography variant="body1" className={styles.body_text}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: data?.primary?.body1?.text
                  }}
                />
              </Typography>
              <Box my={1} className={styles.buttons_wrapper}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <AnchorLink
                      to={data?.primary?.cta_link?.text}
                      className={styles.link_button}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                      >
                        {data?.primary?.cta_text?.text}
                      </Button>
                    </AnchorLink>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {data?.primary?.cta_second_show && (
                      <>
                        {data?.primary?.cta_second_type === "internal" ? (
                          <GatsbyLink
                            to={data?.primary?.cta_second_link?.text}
                            style={{ textDecoration: "none" }}
                            className={styles.link_button}
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              fullWidth
                            >
                              {data?.primary?.cta_second_text?.text}
                            </Button>
                          </GatsbyLink>
                        ) : data?.primary?.cta_second_type === "external" ? (
                          <a
                            href={data?.primary?.cta_second_link?.text}
                            className={styles.link_button}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              fullWidth
                            >
                              {data?.primary?.cta_second_text?.text}
                            </Button>
                          </a>
                        ) : (
                          <a
                            href={data?.primary?.cta_second_pdf_file?.url}
                            className={styles.link_button}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Button
                              variant="contained"
                              color="primary"
                              size="large"
                              fullWidth
                            >
                              {data?.primary?.cta_second_text?.text}
                            </Button>
                          </a>
                        )}
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <GatsbyImage
                fluid={data?.primary?.image?.fluid}
                alt={data?.primary?.title1?.text}
              />
            </Grid>
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default CateringInformation;
