import React from "react";
import { Hero } from "src/components";
import {
  Container,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Box
} from "@material-ui/core";
import { PrismicPageBodyFaqSection } from "src/graphqlTypes";
import styles from "./Styles.module.scss";
import { ExpandMore } from "@material-ui/icons";

type FAQSectionProps = {
  data: PrismicPageBodyFaqSection;
};

const CateringFaqTemplate = ({ data }: FAQSectionProps) => {
  return (
    <Hero size="large" id="faqs">
      <Hero.Body>
        <Container>
          <div className={styles.section__header}>
            <Typography variant="h2">
              <strong>{data.primary.section_title.text}</strong>
            </Typography>
          </div>
          <div className={styles.section__body}>
            <div>
              <Box my={2} mb={4}>
                <Typography variant="h4" color="secondary" gutterBottom>
                  <strong>{data.primary.faq_title.text}</strong>
                </Typography>
              </Box>

              {data.items.map(({ faq_title, faq_content }, idx) => (
                <div key={idx}>
                  <ExpansionPanel className={styles.panel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-content-${idx}`}
                      id={`panel-header-${idx}`}
                    >
                      <Typography>
                        <strong>{faq_title.text}</strong>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography>{faq_content.text}</Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              ))}
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default CateringFaqTemplate;
