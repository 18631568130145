import React, { Fragment, useState } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  useTheme,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextareaAutosize,
  Container,
  Grid
} from "@material-ui/core";
import { PrismicPageBodyCateringForm } from "src/graphqlTypes";
import styles from "./Styles.module.scss";
import { useForm, Controller } from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
import { encodeFormData } from "src/utils/encodeFormData";
import { useStaticQuery, graphql } from "gatsby";

type CateringFormProps = {
  data: PrismicPageBodyCateringForm;
};

const CateringFormTemplate = ({ data }: CateringFormProps) => {
  const {
    handleSubmit,
    register,
    errors,
    reset,
    control,
    watch,
    unregister
  } = useForm();
  const attachmentPdf = useStaticQuery(graphql`
    query AttachmentPdf {
      file(internal: { mediaType: { eq: "application/pdf" } }) {
        id
        fields {
          contents
        }
      }
    }
  `);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);
  const [selectValues, setSelectValues] = useState("");
  const [validateMissedFields, setValidateMissedFields] = useState(false);

  let date = watch("dateOfCateringEvent");
  let dateFormat;
  if (date !== undefined) {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    dateFormat = `${month}/${day}/${year}`;
    unregister("dateOfCateringEvent");
  }
  function handleSelect(checkedName) {
    setSelectValues(checkedName);
  }

  const onSubmit = async data => {
    setValidateMissedFields(true);

    if (selectValues === "") {
      console.log("form not valid");
    } else {
      try {
        const response = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encodeFormData({
            "form-name": "catering-contact-form",
            ...data
          })
        });
        if (response.ok) {
          setSuccess(true);
          const body = data;
          const awsResponse = await window.fetch(
            "/.netlify/functions/catering-aws",
            {
              method: "POST",
              body: JSON.stringify({
                email: data.email,
                senderEmail: PrimaryData.receiver_email.text,
                responseBody: PrimaryData.auto_response_body?.text,
                emailSubject: PrimaryData.email_subject?.text,
                firstName: data.firstName
              })
            }
          );
          reset();
        } else {
          console.error("Request failed", response);
          setError(
            "There was an error submitting your message, please try again."
          );
        }
      } catch (e) {
        console.log(data);
        console.error(e);
        setError("Something went wrong, please try again.");
      }
    }
  };

  const theme = useTheme();

  const InputItems = data?.items;
  const PrimaryData = data?.primary;

  let today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  const yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  return (
    <Fragment>
      <Container id="form">
        <form
          onSubmit={handleSubmit(onSubmit)}
          method="POST"
          name="catering-contact-form"
          netlify-honeypot="bot-field"
          data-netlify="true"
          className={styles.section_form}
        >
          <Typography variant="h2" className={styles.title_form}>
            {PrimaryData?.title_form?.text}
          </Typography>
          <Grid container spacing={4}>
            {InputItems?.length &&
              InputItems?.map(inputItem => {
                const formName = inputItem?.field_name?.text.toLowerCase();

                function camelize(str) {
                  return str
                    .replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
                      return index === 0
                        ? word.toLowerCase()
                        : word.toUpperCase();
                    })
                    .replace(/\s+/g, "");
                }

                const formNameCamelize = camelize(formName);

                return inputItem?.field_type === "Text Field" ? (
                  <Grid item xs={12} md={6} className={styles.grid_item}>
                    <TextField
                      variant="outlined"
                      label={inputItem?.field_name?.text}
                      className={styles.section__input}
                      name={formNameCamelize}
                      inputRef={register({ required: true })}
                      error={!!errors[formNameCamelize]}
                      helperText={
                        errors[formNameCamelize]?.type === "required" &&
                        `${inputItem?.field_name?.text} is required`
                      }
                      fullWidth
                    />
                  </Grid>
                ) : inputItem?.field_type === "Date Field" ? (
                  <Grid item xs={12} md={6} className={styles.grid_item}>
                    <TextField
                      id={formNameCamelize}
                      label={inputItem?.field_name?.text}
                      type="date"
                      variant="outlined"
                      name={formNameCamelize}
                      defaultValue={today}
                      error={!!errors[formNameCamelize]}
                      helperText={
                        errors[formNameCamelize]?.type === "required" &&
                        `${inputItem?.field_name?.text} is required`
                      }
                      inputRef={register({ required: true })}
                      className={styles.section_input_date}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                ) : inputItem?.field_type === "Option Select" ? (
                  <Grid item xs={12} md={6} className={styles.grid_item}>
                    <FormControl className={styles.section__form_control}>
                      <InputLabel className={styles.select_label}>
                        {inputItem?.field_name?.text}
                      </InputLabel>
                      <Controller
                        name={formNameCamelize}
                        control={control}
                        as={
                          <Select
                            id={formNameCamelize}
                            className={styles.section_input_select}
                            fullWidth
                          >
                            <MenuItem
                              onClick={() =>
                                handleSelect(inputItem?.option_select_one?.text)
                              }
                              value={inputItem?.option_select_one?.text}
                            >
                              {inputItem?.option_select_one?.text}
                            </MenuItem>
                            <MenuItem
                              onClick={() =>
                                handleSelect(inputItem?.option_select_one?.text)
                              }
                              value={inputItem?.option_select_two?.text}
                            >
                              {inputItem?.option_select_two?.text}
                            </MenuItem>
                            {inputItem?.option_select_three?.text !== "" && (
                              <MenuItem
                                onClick={() =>
                                  handleSelect(
                                    inputItem?.option_select_one?.text
                                  )
                                }
                                value={inputItem?.option_select_three?.text}
                              >
                                {inputItem?.option_select_three?.text}
                              </MenuItem>
                            )}
                          </Select>
                        }
                      />
                      {validateMissedFields && selectValues === "" ? (
                        <p className={styles.field_error}>
                          This fields is required
                        </p>
                      ) : (
                        <></>
                      )}
                    </FormControl>
                  </Grid>
                ) : inputItem?.field_type === "Long Response" ? (
                  <Grid item xs={12} md={6} className={styles.grid_item}>
                    <FormControl className={styles.form_control}>
                      <Controller
                        name={formNameCamelize}
                        control={control}
                        as={
                          <TextareaAutosize
                            rows={2}
                            aria-label="minimum height"
                            className={styles.text_area}
                            placeholder={inputItem?.field_name?.text}
                          />
                        }
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <></>
                );
              })}
          </Grid>
          <Box display="flex" my={4} clone>
            <ReCaptcha
              sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
              onChange={c => setValue("g-recaptcha-response", c)}
            />
          </Box>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {success && (
                <Box bgcolor={theme.palette.success.main} color="white" p={1}>
                  <Typography>{PrimaryData?.form_response?.text}</Typography>
                </Box>
              )}
              {error && (
                <Box bgcolor={theme.palette.error.main} color="white" p={1}>
                  <Typography>{error}</Typography>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6} className={styles.grid_item}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className={styles.button_submit}
              >
                Submit catering request
              </Button>
            </Grid>
          </Grid>
          <TextField
            variant="outlined"
            className={styles.section__hidden_input}
            value={dateFormat}
            name="cateringDate"
            inputRef={register({ required: true })}
            fullWidth
          />
        </form>
      </Container>
    </Fragment>
  );
};

export default CateringFormTemplate;

/*
    <TextField
      variant="outlined"
      className={styles.section__hidden_input}
      name="attachmentPdf"
      inputRef={register({ required: true })}
      value={attachmentPdf.file.fields.contents}
      fullWidth
    />
*/
