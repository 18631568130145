import React, { useState, Fragment } from "react";
import { PrismicPageBodyContactPrimary } from "src/graphqlTypes";
import { Hero } from "src/components";
import {
  Container,
  Grid,
  Typography,
  Button,
  TextField,
  Box,
  useTheme
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import { Link as GatsbyLink } from "gatsby";
import { encodeFormData } from "src/utils/encodeFormData";
import ReCaptcha from "react-google-recaptcha";
import styles from "./Styles.module.scss";

type PrimaryContactHeroTemplateProps = {
  data: PrismicPageBodyContactPrimary;
};

const PrimaryContactHeroTemplate = ({
  data
}: PrimaryContactHeroTemplateProps) => {
  return (
    <Hero className={styles.hero}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={6} className={styles.grid__item}>
            <Typography variant="h2" gutterBottom>
              <strong>{data.primary.about_title.text}</strong>
            </Typography>

            <div className={styles.grid__item_content}>
              <Typography variant="body1" paragraph>
                {data.primary.about_description.text}
              </Typography>
            </div>

            <Box my={2}>
              <GatsbyLink to="/press" style={{ textDecoration: "none" }}>
                <Button variant="contained" color="secondary" size="large">
                  Read More
                </Button>
              </GatsbyLink>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} className={styles.grid__item}>
            <Typography variant="h2" gutterBottom>
              <strong>{data.primary.contact_title.text}</strong>
            </Typography>

            <div className={styles.grid__item_content}>
              <Typography variant="body1" paragraph>
                {data.primary.contact_description.text}
              </Typography>

              <ContactForm />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Hero>
  );
};

const ContactForm = () => {
  const { handleSubmit, register, errors, setValue, reset } = useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);

  const onSubmit = async data => {
    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({ "form-name": "contact", ...data })
      });

      if (response.ok) {
        setSuccess(true);
        reset();
      } else {
        console.error("Request failed", response);
        setError(
          "There was an error submitting your message, please try again."
        );
      }
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again.");
    }
  };

  const theme = useTheme();

  return (
    <Fragment>
      {success && (
        <Box bgcolor={theme.palette.success.main} color="white" p={2}>
          <Typography>Message was successfully sent!</Typography>
        </Box>
      )}

      {error && (
        <Box bgcolor={theme.palette.error.main} color="white" p={2}>
          <Typography>{error}</Typography>
        </Box>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        name="report-an-issue"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <input
          name="g-recaptcha-response"
          type="hidden"
          ref={register({ required: true })}
        />
        <input name="bot-field" type="hidden" ref={register()} />
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              label="Name"
              name="name"
              margin="normal"
              inputRef={register({ required: true })}
              error={!!errors?.name}
              helperText={
                errors?.name?.type === "required" && "Name is required"
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              type="email"
              variant="outlined"
              label="Email"
              name="email"
              margin="normal"
              inputRef={register({ required: true })}
              error={!!errors?.email}
              helperText={
                errors?.email?.type === "required" && "Email is required"
              }
              fullWidth
            />
          </Grid>
        </Grid>

        <TextField
          variant="outlined"
          label="Message"
          name="message"
          margin="normal"
          rows={5}
          rowsMax={10}
          inputRef={register({ required: true })}
          error={!!errors?.message}
          helperText={
            errors?.message?.type === "required" && "Message is required"
          }
          multiline
          fullWidth
        />

        <Box display="flex" mt={2} clone>
          <ReCaptcha
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            onChange={c => setValue("g-recaptcha-response", c)}
          />
        </Box>

        <Box my={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <GatsbyLink to="/contact" style={{ textDecoration: "none" }}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  fullWidth
                >
                  See FAQs
                </Button>
              </GatsbyLink>
            </Grid>

            <Grid item xs={12} md={6}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                fullWidth
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Fragment>
  );
};

export default PrimaryContactHeroTemplate;
