import React from "react";
import {
  PrismicPageBodyBusinessDetailsSection,
  PrismicContactInfo
} from "src/graphqlTypes";
import { Hero } from "src/components";
import { Container, Typography, Grid } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";
import { formatPhoneNumber } from "src/utils/formatPhoneNumber";
import styles from "./Styles.module.scss";

type BusinessDetailsTemplateProps = {
  data: PrismicPageBodyBusinessDetailsSection;
};

const BusinessDetailsTemplate = ({ data }: BusinessDetailsTemplateProps) => {
  const {
    prismicContactInfo
  }: { prismicContactInfo: PrismicContactInfo } = useStaticQuery(query);

  return (
    <Hero size="large" color="#f5f5f5">
      <Hero.Body>
        <Container>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" color="secondary" gutterBottom>
                <strong>{data.primary.section_title.text}</strong>
              </Typography>

              <div className={styles.detail}>
                <Typography variant="h6" color="textPrimary" component="p">
                  <strong>Location</strong>
                </Typography>

                <Typography>{data.primary.business_location.text}</Typography>
              </div>

              <div className={styles.detail}>
                <Typography variant="h6" color="textPrimary" component="p">
                  <strong>Contact</strong>
                </Typography>

                <Typography>
                  {prismicContactInfo.data.contact_email.text}
                </Typography>
                <Typography>
                  {formatPhoneNumber(prismicContactInfo.data.contact_phone)}
                </Typography>
              </div>

              <div className={styles.detail}>
                <Typography variant="h6" color="textPrimary" component="p">
                  <strong>Hours</strong>
                </Typography>

                <Typography component="div">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: data.primary.business_hours.html
                    }}
                  />
                </Typography>
              </div>
            </Grid>

            <Grid item xs={12} md={6}>
              <div className={styles.map}>
                <iframe
                  className={styles.map__frame}
                  width="100%"
                  height="500"
                  id="gmap_canvas"
                  src="https://maps.google.com/maps?q=Detroit%2075%20Kitchen&t=&z=17&ie=UTF8&iwloc=&output=embed"
                  frameBorder="0"
                  scrolling="no"
                  title="Location"
                />
              </div>
            </Grid>
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const query = graphql`
  query {
    prismicContactInfo {
      data {
        contact_email {
          text
        }
        contact_phone
      }
    }
  }
`;

export default BusinessDetailsTemplate;
