import React from "react";
import { PrismicPageBodyPressKit, PrismicDownloads } from "src/graphqlTypes";
import { Hero } from "src/components";
import { Container, Typography, useTheme, Button } from "@material-ui/core";
import { graphql, useStaticQuery } from "gatsby";

type PressKitTemplateProps = {
  data: PrismicPageBodyPressKit;
};

const PressKitTemplate = ({ data }: PressKitTemplateProps) => {
  const theme = useTheme();
  const {
    prismicDownloads
  }: { prismicDownloads: PrismicDownloads } = useStaticQuery(query);

  return (
    <Hero size="large" color="#f5f5f5">
      <Hero.Body>
        <Container>
          <Typography variant="h2" gutterBottom>
            <strong>{data.primary.section_title.text}</strong>
          </Typography>

          <Typography variant="body1" paragraph>
            {data.primary.section_subtitle.text}
          </Typography>

          <Button
            href={prismicDownloads.data.press_kit_url.url}
            rel="noopener noreferrer"
            target="_blank"
            variant="outlined"
            color="secondary"
            size="large"
            style={{
              padding: theme.spacing(2, 3),
              marginTop: theme.spacing(2)
            }}
          >
            Download Press Kit
          </Button>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const query = graphql`
  query {
    prismicDownloads {
      data {
        press_kit_url {
          url
        }
      }
    }
  }
`;

export default PressKitTemplate;
