import React from "react";
import { Hero } from "src/components";
import {
  Typography,
  Container,
  Box,
  Button,
  useTheme
} from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import { PrismicPageBodySecondaryStorySection } from "src/graphqlTypes";

type SecondaryStorySectionTemplateProps = {
  data: PrismicPageBodySecondaryStorySection;
};

export const SecondaryStorySectionTemplate = ({
  data
}: SecondaryStorySectionTemplateProps) => {
  const theme = useTheme();

  return (
    <Hero
      size="large"
      color="#F5F5F5"
      style={{
        borderTop: "1px solid rgba(112, 112, 112, 0.2)",
        borderBottom: "1px solid rgba(112, 112, 112, 0.2)"
      }}
    >
      <Hero.Body>
        <Container>
          <Box
            display="grid"
            gridTemplateAreas={{
              xs: `
              "image"
              "content"
            `,
              md: `"content image"`
            }}
            gridTemplateColumns={["1fr", "minmax(0, 1fr) minmax(0, 1fr)"]}
            gridTemplateRows={["1fr auto", "1fr"]}
            gridGap={theme.spacing(12)}
            overflow="hidden"
          >
            <Box gridArea="content">
              <Typography variant="h1" component="h2" gutterBottom>
                <strong>
                  {data.primary.secondary_story_section_title.text}
                </strong>
              </Typography>

              {data.primary?.secondary_story_section_content &&
                data.primary.secondary_story_section_content.raw.map(
                  (paragraph, idx) => (
                    <Typography
                      color="textPrimary"
                      key={`story-${data.primary.secondary_story_section_title.text}-${idx}`}
                      style={{ marginBottom: theme.spacing(4) }}
                    >
                      {paragraph.text}
                    </Typography>
                  )
                )}

              {data.primary?.secondary_story_section_link?.url && (
                <Button
                  color="secondary"
                  size="large"
                  variant="contained"
                  style={{
                    padding: theme.spacing(1.5, 6),
                    textDecoration: "none"
                  }}
                  href={data.primary.secondary_story_section_link.url}
                  key={`action-button-story-${data.primary.secondary_story_section_title.text}`}
                >
                  Read More
                </Button>
              )}
            </Box>

            <Box gridArea="image" clone>
              <GatsbyImage
                fluid={data.primary.secondary_story_section_image.fluid}
                alt={data.primary.secondary_story_section_image?.alt}
              />
            </Box>
          </Box>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default SecondaryStorySectionTemplate;
