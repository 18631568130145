import React from "react";
import { Hero } from "src/components";
import { PrismicPageBodyTextSection } from "src/graphqlTypes";
import { Container } from "@material-ui/core";

type TextSectionTemplateProps = {
  data: PrismicPageBodyTextSection;
};

const TextSectionTemplate = ({ data }: TextSectionTemplateProps) => {
  return (
    <Hero>
      <Hero.Body>
        <Container>
          <div
            dangerouslySetInnerHTML={{ __html: data.primary.content.html }}
          />
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default TextSectionTemplate;
