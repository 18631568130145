import React from "react";
import { PrismicPageBodyAwards } from "src/graphqlTypes";
import { Hero, Collage } from "src/components";
import { Container, Typography, Grid, Box, Button } from "@material-ui/core";
import { FluidObject } from "gatsby-image";
import { Link as GatsbyLink } from "gatsby";

type AwardsHeroTemplateProps = {
  data: PrismicPageBodyAwards;
};

const AwardsHeroTemplate = ({ data }: AwardsHeroTemplateProps) => {
  const images = data.items.map(i => ({
    image: i.image.fluid as FluidObject,
    alt: i.image.alt
  }));

  return (
    <Hero size="large" color="#f5f5f5">
      <Hero.Body>
        <Container style={{ overflow: "hidden" }}>
          <Grid container spacing={10}>
            <Grid item xs={12} md={6}>
              <Typography variant="h2" gutterBottom>
                <strong>{data.primary.section_title.text}</strong>
              </Typography>

              <Typography variant="body1" paragraph>
                {data.primary.section_description.text}
              </Typography>

              <Box my={4}>
                <Typography variant="h6" component="p">
                  <strong>{data.primary.award_one_title.text}</strong>
                </Typography>

                <Typography variant="body1" component="p" color="secondary">
                  <strong>{data.primary.award_one_subtitle.text}</strong>
                </Typography>
              </Box>

              <Box my={4}>
                <Typography variant="h6" component="p">
                  <strong>{data.primary.award_two_title.text}</strong>
                </Typography>

                <Typography variant="body1" component="p" color="secondary">
                  <strong>{data.primary.award_two_subtitle.text}</strong>
                </Typography>
              </Box>

              <Box my={4}>
                <Typography variant="h6" component="p">
                  <strong>{data.primary.award_three_title.text}</strong>
                </Typography>

                <Typography variant="body1" component="p" color="secondary">
                  <strong>{data.primary.award_three_subtitle.text}</strong>
                </Typography>
              </Box>

              <Box my={2}>
                <GatsbyLink to="/press" style={{ textDecoration: "none" }}>
                  <Button variant="contained" color="secondary" size="large">
                    Read More
                  </Button>
                </GatsbyLink>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Collage images={images} />
            </Grid>
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default AwardsHeroTemplate;
