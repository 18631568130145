import React from "react";
import { Hero } from "src/components";
import { PrismicPageBodyPressLogos } from "src/graphqlTypes";
import { Container, Typography } from "@material-ui/core";
import styles from "./Styles.module.scss";

type PressBannerTemplateProps = {
  data: PrismicPageBodyPressLogos;
};

const PressBannerTemplate = ({ data }: PressBannerTemplateProps) => {
  return (
    <Hero color="#f5f5f5">
      {data?.primary?.title1?.text && (
        <Container>
          <Typography variant="h3" className={styles.section_title}>
            {data?.primary?.title1?.text}
          </Typography>
        </Container>
      )}
      <Hero.Body>
        <Container>
          <div className={styles.grid}>
            {data.items.map(({ image }, idx) => (
              <img src={image.url} alt={image.alt} key={idx} />
            ))}
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default PressBannerTemplate;
