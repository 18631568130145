import React from "react";
import { Hero, Nav } from "src/components";
import {
  Typography,
  Container,
  Button,
  Grid,
  useTheme,
  Hidden
} from "@material-ui/core";
import { PrismicPageBodyHero, PrismicSite } from "src/graphqlTypes";
import { FluidObject } from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";

type MainSliderTemplateProps = {
  data: PrismicPageBodyHero;
};

const MainSliderTemplate = ({ data }: MainSliderTemplateProps) => {
  const theme = useTheme();

  const {
    prismicSite
  }: {
    prismicSite: PrismicSite;
  } = useStaticQuery(query);

  return (
    <Hero
      color="#000"
      image={data?.primary?.image?.fluid as FluidObject}
      fullHeight
    >
      <Hero.Header>
        <Nav />
      </Hero.Header>

      <Hero.Body>
        <Container>
          {data.primary?.hero_caption && (
            <Typography variant="h5" component="p">
              {data.primary.hero_caption.text}
            </Typography>
          )}

          {data.primary?.hero_title && (
            <Typography variant="h1" style={{ maxWidth: 975 }} gutterBottom>
              <strong>{data.primary.hero_title.text}</strong>
            </Typography>
          )}

          <Grid container>
            <Grid item xs={12} md={3}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                style={{ padding: theme.spacing(2, 3) }}
                fullWidth
                onClick={() =>
                  window.scroll({
                    top: document.getElementById("menu").offsetTop,
                    behavior: "smooth"
                  })
                }
              >
                See Menu
              </Button>

              <Hidden mdUp implementation="css">
                <Button
                  href={prismicSite?.data?.order_link?.url ?? null}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="outlined"
                  color="primary"
                  size="large"
                  style={{
                    padding: theme.spacing(2, 3),
                    marginTop: theme.spacing(1)
                  }}
                  fullWidth
                >
                  Order Now
                </Button>
              </Hidden>
            </Grid>
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const query = graphql`
  query {
    prismicSite {
      data {
        order_link {
          url
        }
      }
    }
  }
`;

export default MainSliderTemplate;
