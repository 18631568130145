import React from "react";
import { Hero } from "src/components";
import { Typography, Container, Box, useTheme } from "@material-ui/core";
import GatsbyImage from "gatsby-image";
import { PrismicPageBodyPrimarystorysection } from "src/graphqlTypes";

type MainStorySectionTemplateProps = {
  data: PrismicPageBodyPrimarystorysection;
};

const MainStorySectionTemplate = ({ data }: MainStorySectionTemplateProps) => {
  const theme = useTheme();
  return (
    <Hero size="large">
      <Hero.Body>
        <Container>
          <Box
            display="grid"
            gridTemplateAreas={{
              xs: `
              "image"
              "content"
            `,
              md:
                data.primary.story_section_image_position === "right"
                  ? `"content image"`
                  : `"image content"`
            }}
            gridTemplateColumns={["1fr", "minmax(0, 1fr) minmax(0, 1fr)"]}
            gridTemplateRows={["1fr auto", "1fr"]}
            gridGap={40}
          >
            <Box gridArea="content">
              {data.primary?.story_section_title && (
                <Typography variant="h2" gutterBottom>
                  <strong>{data.primary.story_section_title.text}</strong>
                </Typography>
              )}

              {data.primary?.story_section_subtitle && (
                <Typography
                  variant="h4"
                  component="p"
                  color="error"
                  style={{ marginBottom: theme.spacing(3) }}
                >
                  <strong>{data.primary.story_section_subtitle.text}</strong>
                </Typography>
              )}

              {data.primary?.story_section_content &&
                data.primary.story_section_content.raw.map((paragraph, idx) => (
                  <Typography
                    color="textPrimary"
                    key={`story-${data.primary.story_section_title.text}-${idx}`}
                    paragraph
                  >
                    {paragraph.text}
                  </Typography>
                ))}
            </Box>

            <Box gridArea="image" clone>
              <GatsbyImage
                fluid={data.primary.story_section_image.fluid}
                alt={data.primary.story_section_image?.alt}
              />
            </Box>
          </Box>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default MainStorySectionTemplate;
