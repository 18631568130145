import React, { Fragment, useState } from "react";
import { Hero } from "src/components";
import {
  Container,
  Typography,
  Button,
  Select,
  MenuItem,
  ExpansionPanel,
  FormControl,
  InputLabel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  TextField,
  Box,
  useTheme
} from "@material-ui/core";
import { PrismicPageBodyFaqSection, PrismicDownloads } from "src/graphqlTypes";
import styles from "./Styles.module.scss";
import { ExpandMore } from "@material-ui/icons";
import { useForm } from "react-hook-form";
import ReCaptcha from "react-google-recaptcha";
import { graphql, useStaticQuery } from "gatsby";
import { encodeFormData } from "src/utils/encodeFormData";
import { navigate } from "gatsby";

type FAQSectionProps = {
  data: PrismicPageBodyFaqSection;
};

const FAQSectionTemplate = ({ data }: FAQSectionProps) => {
  const {
    prismicDownloads
  }: { prismicDownloads: PrismicDownloads } = useStaticQuery(query);
  return (
    <Hero size="large">
      <Hero.Body>
        <Container>
          <div className={styles.section__header}>
            <Typography variant="h3">
              <strong>{data.primary.section_title.text}</strong>
            </Typography>

            <div className={styles.section__header_buttons}>
              <div>
                <Button
                  href={prismicDownloads.data.press_kit_url.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="outlined"
                  color="secondary"
                  size="large"
                >
                  Download Press Kit
                </Button>
              </div>

              <div>
                <Button
                  href={prismicDownloads.data.menu_url.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  variant="outlined"
                  color="secondary"
                  size="large"
                >
                  Download Our Menu
                </Button>
              </div>
            </div>
          </div>
          <div className={styles.section__body}>
            <div>
              <Box my={2} mb={4}>
                <Typography variant="h4" color="secondary" gutterBottom>
                  <strong>{data.primary.faq_title.text}</strong>
                </Typography>
              </Box>

              {data.items.map(({ faq_title, faq_content }, idx) => (
                <div key={idx}>
                  <ExpansionPanel className={styles.panel}>
                    <ExpansionPanelSummary
                      expandIcon={<ExpandMore />}
                      aria-controls={`panel-content-${idx}`}
                      id={`panel-header-${idx}`}
                    >
                      <Typography align="left">
                        <strong>{faq_title.text}</strong>
                      </Typography>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Typography align="left">{faq_content.text}</Typography>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </div>
              ))}
            </div>

            <div>
              <Box my={2} mb={4}>
                <Typography variant="h4" color="secondary" gutterBottom>
                  <strong>{data.primary.contact_title.text}</strong>
                </Typography>
              </Box>

              <ContactForm data={data} />
            </div>
          </div>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

const ContactForm = ({ data }) => {
  const { handleSubmit, register, errors, setValue, reset } = useForm();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(undefined);

  const onSubmit = async data => {
    try {
      const response = await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encodeFormData({ "form-name": "contact", ...data })
      });

      if (response.ok) {
        setSuccess(true);
        reset();
      } else {
        console.error("Request failed", response);
        setError(
          "There was an error submitting your message, please try again."
        );
      }
    } catch (e) {
      console.error(e);
      setError("Something went wrong, please try again.");
    }
  };

  const theme = useTheme();
  function handleSelect(checkedName) {
    if (checkedName === "Catering Form Submission") {
      navigate("/catering/#form");
    }
  }
  return (
    <Fragment>
      {success && (
        <Box bgcolor={theme.palette.success.main} color="white" p={2}>
          <Typography>Message was successfully sent!</Typography>
        </Box>
      )}

      {error && (
        <Box bgcolor={theme.palette.error.main} color="white" p={2}>
          <Typography>{error}</Typography>
        </Box>
      )}

      <form
        onSubmit={handleSubmit(onSubmit)}
        method="POST"
        name="contact"
        netlify-honeypot="bot-field"
        data-netlify="true"
      >
        <FormControl className={styles.section__form_control}>
          <InputLabel className={styles.select_label}>
            {data.primary.option_selec_title.text}
          </InputLabel>
          <Select className={styles.section_input_select} fullWidth>
            <MenuItem
              onClick={() =>
                handleSelect(data.primary.form_option_select_one.text)
              }
              value={data.primary.form_option_select_one.text}
            >
              General Form Submission
            </MenuItem>
            <MenuItem
              onClick={() =>
                handleSelect(data.primary.form_option_select_two.text)
              }
              value={data.primary.form_option_select_two.text}
            >
              Catering Form Submission
            </MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          label="Name"
          name="name"
          inputRef={register({ required: true })}
          error={!!errors?.name}
          helperText={errors?.name?.type === "required" && "Name is required"}
          fullWidth
        />

        <TextField
          type="email"
          variant="outlined"
          label="Email"
          name="email"
          margin="normal"
          inputRef={register({ required: true })}
          error={!!errors?.email}
          helperText={errors?.email?.type === "required" && "Email is required"}
          fullWidth
        />

        <TextField
          variant="outlined"
          label="Message"
          name="message"
          margin="normal"
          rows={5}
          rowsMax={10}
          inputRef={register({ required: true })}
          error={!!errors?.message}
          helperText={
            errors?.message?.type === "required" && "Message is required"
          }
          multiline
          fullWidth
        />

        <Box display="flex" my={2} clone>
          <ReCaptcha
            sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
            onChange={c => setValue("g-recaptcha-response", c)}
          />
        </Box>

        <Button
          type="submit"
          variant="contained"
          color="secondary"
          size="large"
          fullWidth
        >
          Submit
        </Button>
      </form>
    </Fragment>
  );
};

const query = graphql`
  query {
    prismicDownloads {
      data {
        menu_url {
          url
        }
        press_kit_url {
          url
        }
      }
    }
  }
`;

export default FAQSectionTemplate;
