import React from "react";
import { Hero } from "src/components";
import { Container, Box, Button, useTheme } from "@material-ui/core";
import { Link as GatsbyLink } from "gatsby";
import { PrismicPageBodyActionButtonGroup } from "src/graphqlTypes";

type ActionButtonGroupTemplateProps = {
  data: PrismicPageBodyActionButtonGroup;
};

export const ActionButtonGroupTemplate = ({
  data
}: ActionButtonGroupTemplateProps) => {
  const theme = useTheme();

  return (
    <Hero size="large">
      <Hero.Body>
        <Container>
          <Box
            display="grid"
            gridTemplateRows="1fr"
            gridAutoFlow="column"
            gridColumnGap={16}
            justifyContent="center"
          >
            {data.items.map((button, idx) =>
              button?.button_group_internal_link?.text ? (
                <GatsbyLink
                  to={button.button_group_internal_link.text}
                  style={{ textDecoration: "none" }}
                  key={`action-button-${button.button_group_button_text.text}-${idx}`}
                >
                  <Button
                    color="secondary"
                    size="large"
                    variant={
                      button.button_group_button_style === "outlined"
                        ? "outlined"
                        : "contained"
                    }
                    style={{
                      padding: theme.spacing(1.5, 6),
                      height: "100%"
                    }}
                  >
                    {button.button_group_button_text.text}
                  </Button>
                </GatsbyLink>
              ) : (
                <Button
                  color="secondary"
                  size="large"
                  variant={
                    button.button_group_button_style === "outlined"
                      ? "outlined"
                      : "contained"
                  }
                  style={{
                    padding: theme.spacing(1.5, 6),
                    height: "100%",
                    textDecoration: "none"
                  }}
                  href={button.button_group_button_link.url}
                  key={`action-button-${button.button_group_button_text.text}-${idx}`}
                >
                  {button.button_group_button_text.text}
                </Button>
              )
            )}
          </Box>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default ActionButtonGroupTemplate;
