import React from "react";
import { Hero } from "src/components";
import { Container, Grid, Typography, Box } from "@material-ui/core";
import { PrismicPageBodyGallery } from "src/graphqlTypes";
import styles from "./Styles.module.scss";
import GatsbyImage from "gatsby-image";

type GallerySectionProps = {
  data: PrismicPageBodyGallery;
};

const GalleryTemplate = ({ data }: GallerySectionProps) => {
  return (
    <Hero size="large">
      <Hero.Body>
        <Container className={styles.section_wrapper}>
          <Grid container spacing={4}>
            {data?.items?.length &&
              data?.items?.map(galleryItem => {
                return (
                  <Grid
                    key={
                      galleryItem?.gallery_item?.document?.data?.picture_title
                        ?.text
                    }
                    item
                    xs={12}
                    md={4}
                    className={styles.gallery_item}
                  >
                    <Box mb={3} clone>
                      <GatsbyImage
                        fluid={
                          galleryItem?.gallery_item?.document?.data?.picture
                            .fluid
                        }
                        alt={
                          galleryItem?.gallery_item?.document?.data
                            ?.picture_title?.text
                        }
                      />
                    </Box>
                    <Typography>
                      {
                        galleryItem?.gallery_item?.document?.data?.picture_title
                          ?.text
                      }
                    </Typography>
                  </Grid>
                );
              })}
          </Grid>
        </Container>
      </Hero.Body>
    </Hero>
  );
};

export default GalleryTemplate;

/*

                  <GatsbyImage fluid={galleryItem?.gallery_item?.document?.data?.picture?.fluid}/>
                 

*/
